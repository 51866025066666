<template>
  <b-modal
    id="organization-user-form-modal"
    v-model="showModal"
    :title="isEdit? 'Edit User' : 'Create New User'"
    centered
    hide-footer
    @shown="initModal"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <template v-if="!isEdit">
        <b-form-group
          class="mb-3 required"
          label="Name"
          label-for="input-user-name"
        >
          <b-form-input
            id="input-user-name"
            v-model="form.name"
            type="text"
            placeholder="Enter name"
            :state="v$.form.name.$dirty ? (!v$.form.name.$error? null : false) : null"
          />
          <b-form-invalid-feedback
            v-if="v$.form.name.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.name.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="mb-3 required"
          label="Email"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="form.email"
            type="email"
            placeholder="Enter email"
            :state="v$.form.email.$dirty ? (!v$.form.email.$error? null : false) : null"
          />
          <b-form-invalid-feedback
            v-if="v$.form.email.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.email.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="mb-3 required"
          label="Password"
          label-for="input-password"
        >
          <b-input-group>
            <b-form-input
              id="input-password"
              v-model="form.password"
              :type="showPassword ? 'text': 'password'"
              class="form-control pe-5"
              style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
              placeholder="Enter password"
              :state="v$.form.password.$dirty ? (!v$.form.password.$error? null : false) : null"
            />
            <b-input-group-append>
              <div
                class="d-flex cancel-search-icon h-100 px-3"
                :class="v$.form.password.$error ? 'is-invalid' : ''"
                style="position: relative;"
                @click="showPassword = !showPassword"
              >
                <i
                  class="my-auto"
                  :class="showPassword ? 'ri-eye-off-fill': 'ri-eye-fill'"
                />
              </div>
            </b-input-group-append>
          </b-input-group>
          <div class="float-end text-muted small mt-1">
            {{ form.password?.length ?? 0 }}
          </div>
          <b-form-invalid-feedback
            v-if="v$.form.password.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.password.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
          <div 
            v-if="!v$.form.password.$error"
            class="text-muted mt-1"
          >
            <small>Passwords must contain at least six characters.</small>
          </div>
        </b-form-group>
        <b-form-group
          class="mb-3 required"
          label="Password Confirmation"
          label-for="input-password-confirmation"
        >
          <b-input-group>
            <b-form-input
              id="input-password-confirmation"
              v-model="form.password_confirmation"
              :type="showPasswordConfirmation ? 'text': 'password'"
              class="form-control pe-5"
              style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
              placeholder="Enter password confirmation"
              :state="v$.form.password_confirmation.$dirty ? (!v$.form.password_confirmation.$error? null : false) : null"
            />
            <b-input-group-append>
              <div
                class="d-flex cancel-search-icon h-100 px-3"
                style="position: relative;"                
                :class="v$.form.password_confirmation.$error ? 'is-invalid' : ''"
                @click="showPasswordConfirmation = !showPasswordConfirmation"
              >
                <i
                  class="my-auto"
                  :class="showPasswordConfirmation ? 'ri-eye-off-fill': 'ri-eye-fill'"
                />
              </div>
            </b-input-group-append>
          </b-input-group>
          <div class="float-end text-muted small mt-1">
            {{ form.password?.length ?? 0 }}
          </div>
          <b-form-invalid-feedback
            v-if="v$.form.password_confirmation.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.password_confirmation.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
          <div
            v-if="!v$.form.password_confirmation.$error"
            class="text-muted mt-1"
          >
            <small>Passwords must contain at least six characters.</small>
          </div>
        </b-form-group>
        <b-form-group
          class="mb-3 required"
          label="Role"
        >
          <FormInputSelect
            v-model="form.organization_role"
            :options="roleOptions"
            :dropdown-only="true"
            :is-error="v$.form.organization_role.$error ? true : false" 
            placeholder="Select role"
          />
          <b-form-invalid-feedback
            v-if="v$.form.organization_role.$error"
            class="d-inline"
          >
            <span
              v-for="(error, index) in v$.form.organization_role.$errors"
              :key="index"
            >
              {{ error.$message + ' ' }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <template v-else>
        <b-tabs
          v-model="tabIndex"
          justified
          active-nav-item-class="custom-tabs"
          content-class="no-border"
          nav-class="fw-bold mb-3"
        >
          <b-tab
            active
            title="Change Information"
          >
            <b-form-group
              class="mb-3 required"
              label="Name"
              label-for="input-user-name"
            >
              <b-form-input
                id="input-user-name"
                v-model="form.name"
                type="text"
                placeholder="Enter name"
                :state="v$.form.name.$dirty ? (!v$.form.name.$error? null : false) : null"
              />
              <b-form-invalid-feedback
                v-if="v$.form.name.$error"
                class="d-inline"
              >
                <span
                  v-for="(error, index) in v$.form.name.$errors"
                  :key="index"
                >
                  {{ error.$message + ' ' }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="mb-3 required"
              label="Email"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                v-model="form.email"
                type="email"
                placeholder="Enter email"
                :state="v$.form.email.$dirty ? (!v$.form.email.$error? null : false) : null"
              />
              <b-form-invalid-feedback
                v-if="v$.form.email.$error"
                class="d-inline"
              >
                <span
                  v-for="(error, index) in v$.form.email.$errors"
                  :key="index"
                >
                  {{ error.$message + ' ' }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="mb-3 required"
              label="Role"
            >
              <FormInputSelect
                v-model="form.organization_role"
                :options="roleOptions"
                :dropdown-only="true"
                :is-error="v$.form.organization_role.$error ? true : false" 
                placeholder="Select role"
              />
              <b-form-invalid-feedback
                v-if="v$.form.organization_role.$error"
                class="d-inline"
              >
                <span
                  v-for="(error, index) in v$.form.organization_role.$errors"
                  :key="index"
                >
                  {{ error.$message + ' ' }}
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-tab> 
          <b-tab title="Change Password">
            <b-form-group
              class="mb-3 required"
              label="New Password"
              label-for="input-password"
            >
              <b-input-group>
                <b-form-input
                  id="input-password"
                  v-model="form.password"
                  :type="showPassword ? 'text': 'password'"
                  class="form-control pe-5"
                  style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
                  placeholder="Enter password"
                  :state="v$.form.password.$dirty ? (!v$.form.password.$error? null : false) : null"
                />
                <b-input-group-append>
                  <div
                    class="d-flex cancel-search-icon h-100 px-3"
                    :class="v$.form.password.$error ? 'is-invalid' : ''"
                    style="position: relative;"
                    @click="showPassword = !showPassword"
                  >
                    <i
                      class="my-auto"
                      :class="showPassword ? 'ri-eye-off-fill': 'ri-eye-fill'"
                    />
                  </div>
                </b-input-group-append>
              </b-input-group>
              <div class="float-end text-muted small mt-1">
                {{ form.password?.length ?? 0 }}
              </div>
              <b-form-invalid-feedback
                v-if="v$.form.password.$error"
                class="d-inline"
              >
                <span
                  v-for="(error, index) in v$.form.password.$errors"
                  :key="index"
                >
                  {{ error.$message + ' ' }}
                </span>
              </b-form-invalid-feedback>
              <div 
                v-if="!v$.form.password.$error"
                class="text-muted mt-1"
              >
                <small>Passwords must contain at least six characters.</small>
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3 required"
              label="Confirm Password"
              label-for="input-password-confirmation"
            >
              <b-input-group>
                <b-form-input
                  id="input-password-confirmation"
                  v-model="form.password_confirmation"
                  :type="showPasswordConfirmation ? 'text': 'password'"
                  class="form-control pe-5"
                  style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
                  placeholder="Enter password confirmation"
                  :state="v$.form.password_confirmation.$dirty ? (!v$.form.password_confirmation.$error? null : false) : null"
                />
                <b-input-group-append>
                  <div
                    class="d-flex cancel-search-icon h-100 px-3"
                    style="position: relative;"                
                    :class="v$.form.password_confirmation.$error ? 'is-invalid' : ''"
                    @click="showPasswordConfirmation = !showPasswordConfirmation"
                  >
                    <i
                      class="my-auto"
                      :class="showPasswordConfirmation ? 'ri-eye-off-fill': 'ri-eye-fill'"
                    />
                  </div>
                </b-input-group-append>
              </b-input-group>
              <div class="float-end text-muted small mt-1">
                {{ form.password_confirmation?.length ?? 0 }}
              </div>
              <b-form-invalid-feedback
                v-if="v$.form.password_confirmation.$error"
                class="d-inline"
              >
                <span
                  v-for="(error, index) in v$.form.password_confirmation.$errors"
                  :key="index"
                >
                  {{ error.$message + ' ' }}
                </span>
              </b-form-invalid-feedback>
              <div
                v-if="!v$.form.password_confirmation.$error"
                class="text-muted mt-1"
              >
                <small>Passwords must contain at least six characters.</small>
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </template>

      <div class="d-flex justify-content-between gap-2 mt-4">
        <b-button
          variant="outline-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <b-button
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import FormInputSelect from '@/components/FormInputSelect.vue';
import { minLength, sameAs, required, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { isEmptyObject } from '@/utils/helper';
import { roleOptions } from '@/utils/constant';
import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    FormInputSelect
  },
  props: {
    userDetail: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() };
  },
  validations() {
    if (!this.isEdit) {
      // New Data validation (all field is required)
      return {
        form: {
          name: {
            required: helpers.withMessage("Name field is required.", required)
          },
          email: {
            required: helpers.withMessage("Email field is required.", required),
            email: helpers.withMessage("Please enter a valid email address.", email)
          },
          organization_role: {
            required: helpers.withMessage("Role field is required.", required)
          },
          password: {
            required: helpers.withMessage("Password field is required.", required),
            minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6))
          },
          password_confirmation: {
            required: helpers.withMessage("Confirm password field is required.", required),
            minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6)),
            sameAsPassword: helpers.withMessage("Password doesn't match.", sameAs(this.form.password))
          },
        },
      }
    } else if (this.isEdit && !this.tabIndex) {
      // Edit user information
      return {
        form: {
          name: {
            required: helpers.withMessage("Name field is required.", required)
          },
          email: {
            required: helpers.withMessage("Email field is required.", required),
            email: helpers.withMessage("Please enter a valid email address.", email)
          },
          organization_role: {
            required: helpers.withMessage("Role field is required.", required)
          },
          password: {},
          password_confirmation: {}
        },
      }
    } 
    return {
      form: {
        name: {},
        email: {},
        organization_role: {},
        password: {
          required: helpers.withMessage("New password field is required.", required),
          minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6))
        },
        password_confirmation: {
          required: helpers.withMessage("Confirm password field is required.", required),
          minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6)),
          sameAsPassword: helpers.withMessage("Password doesn't match.", sameAs(this.form.password))
        },
      },
    }  
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        organization_role: null,
        password: null,
        password_confirmation: null,
      },
      showModal: false,
      showPassword: false,
      showPasswordConfirmation: false,
      tabIndex: 0,
    };
  },
  computed: {
    roleOptions(){
      return roleOptions
    },
    isEdit() {
      return !isEmptyObject(this.userDetail);
    },
    organizationId() {
      return this.$route.params.organizationId ?? this.$store.state.organization.selectedOrganization
    },
    userId() {
      return this.userDetail?.id;
    }
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler(val) {
        if(val) {
          this.form.name = this.userDetail.name
          this.form.email = this.userDetail.email
          this.form.organization_role = this.userDetail.organization_role
        }
      },
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.userDetail[key];
      });
      this.tabIndex = 0
      this.showPassword = false
      this.showPasswordConfirmation = false
    },
    async submitForm() {
      try {
        if (this.isEdit) {
          await this.$api.updateOrganizationUser(this.organizationId, this.userId, this.form);
          this.$emit('update-data', 'Edited');
          // toast 
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Updated Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        } else {
          await this.$api.createOrganizationUser(this.organizationId, this.form);
          this.$emit('update-data', 'Saved');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Saved Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        }
        this.closeModal();
      } catch (error) {
        console.log(error);
        console.log(error.message);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    initModal() {
      this.v$.$reset()
      if (this.isEdit) {
        this.form = { ...this.userDetail };
      } else {
        this.resetForm();
      }
    },
  },
};
</script>
