<template>
  <Layout :items="items">
    <PageHeader :title="organizationDetail.name">
      <template #button>
        <div
          v-if="userRole <= 1"
          class="flex-shrink-0 text-end"
        >
          <button
            class="btn btn-outline-info"
            data-bs-toggle="modal"
            href="#organization-form-modal"
          >
            <i class="ri-edit-2-line" />
          </button>
        </div>
      </template>  
    </PageHeader>
    <b-row>
      <div>
        <b-card-body
          class="rounded"
          style="border: 1px #CED4DA solid;"
        >
          <div>
            <div class="d-flex ms-2 py-1">
              <img
                :src="require(`@/assets/images/icons/organization-description.svg`)"
                style="height: 22px;"
                alt=""
                class="my-auto"
              >
              <div class="my-auto ms-2">
                {{ organizationDetail.description ?? '-' }}
              </div>
            </div>
            <div class="d-flex ms-2 py-1">
              <img
                :src="require(`@/assets/images/icons/organization-timezone.svg`)"
                style="height: 22px;"
                alt=""
                class="my-auto"
              >
              <div class="my-auto ms-2">
                {{ timezone }}
              </div>
            </div>
          </div>
        </b-card-body>
      </div>
      <div>
        <SectionHeader title="Users Management" />
        <div>
          <Pagination
            :meta-data="organizationUsersMeta"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          >
            <template #button>
              <b-col
                v-if="userRole <= 1"
                lg="8"
              >
                <SearchBar
                  placeholder="Search User"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
              <b-col
                v-else
                lg="12"
              >
                <SearchBar
                  placeholder="Search User"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
              <b-col
                v-if="userRole <= 1"
                lg="4"
                class="ps-0"
              >
                <div class="d-flex gap-2">
                  <button
                    class="btn btn-outline-primary w-100"
                    data-bs-toggle="modal"
                    href="#organization-user-form-modal"
                    @click="userDetail = {}"
                  >
                    Create New User
                  </button>
                  <button
                    class="btn btn-primary w-100"
                    data-bs-toggle="modal"
                    href="#organization-attach-user-modal"
                    @click="userDetail = {}"
                  >
                    Attach Existing User
                  </button>
                </div>
              </b-col>
            </template>
            <template #table>
              <DataTable
                :fields="tableFields"
                :items="organizationUsers"
                :allow-local-sort="true"
                :is-loading="isLoading"
              >
                <template #cell(name)="{row}">
                  <span
                    v-html="highlightString(row.item.name, filter.keyword)"
                  />
                </template>
                <template #cell(email)="{row}">
                  <span
                    v-html="highlightString(row.item.email, filter.keyword)"
                  />
                </template>
                <template #cell(organization_role)="{row}">
                  <span class="text-capitalize">{{ row.item.organization_role ?? '' }}</span>
                </template>
                <template #cell(action)="{row}">
                  <b-button
                    v-if="userRole <= 1"
                    variant="outline-info"
                    class="me-2"
                    data-bs-toggle="modal"
                    href="#organization-user-form-modal"
                    @click="userDetail = row.item"
                  >
                    Edit
                  </b-button>
                  <b-button
                    v-if="userRole <= 1"
                    variant="outline-danger"
                    data-bs-toggle="modal"
                    href="#detach-user-modal"
                    @click="userDetail = row.item"
                  >
                    Detach User
                  </b-button>
                </template>
              </DataTable>
              <div
                v-if="!organizationUsers.length && !isLoading"
                class="text-small text-center mb-3"
              >
                {{ filter.keyword ? 'Sorry, no users match your search.' : 'Oops! There is no attached user yet.' }}
              </div>
            </template>
          </Pagination>
        </div>
      </div>
    </b-row>
    <DeleteModal 
      entity="Organization"
      :name="organizationDetail.name ?? ''"
      @delete-data="deleteData"
    />
    <OrganizationFormModal
      :organization-detail="organizationDetail"
      @update-data="updateData"
    />
    <OrganizationAttachUserModal
      :organization-detail="organizationDetail"
      @update-data="updateData"
    />
    <OrganizationDetachUserModal 
      :item="userDetail"
      :organization-detail="organizationDetail"
      @update-data="updateData"
    />
    <OrganizationUserFormModal
      :user-detail="userDetail"
      @update-data="updateData"
    />
  </Layout>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/PageHeader";
  import DeleteModal from '@/components/DeleteModal.vue';
  import OrganizationFormModal from '@/components/organization/OrganizationFormModal.vue';
  import OrganizationAttachUserModal from '@/components/organization/OrganizationAttachUserModal.vue';
  import OrganizationDetachUserModal from '@/components/organization/OrganizationDetachUserModal.vue';
  import OrganizationUserFormModal from '@/components/organization/OrganizationUserFormModal.vue';
  
  import Pagination from '@/components/Pagination.vue';
  import DataTable from '@/components/DataTable.vue';
  import SearchBar from '@/components/SearchBar.vue';

  import { highlightString } from '@/utils/helper';
  import { timezoneOptions } from '@/utils/constant';

  import { useToast } from 'vue-toastification';
  import ToastDefault from '@/components/ToastDefault.vue';


  export default {
    components: {
      Layout,
      PageHeader,
      DeleteModal,
      OrganizationDetachUserModal,
      OrganizationFormModal, 
      OrganizationAttachUserModal,
      OrganizationUserFormModal,
      Pagination,
      SearchBar,
      DataTable
    },
    setup () {
      const toast = useToast();
      return {
        toast,
      }
    },
    data() {
      return {
        filter: {
          keyword: '',
          per_page: 10,
          page: 1,
        },
        organizationDetail: {},
        organizationUsers: [],
        organizationUsersMeta: {},
        userDetail: {},
        isLoading: false,
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),

      organizationId() {
        return this.$route.params.organizationId ?? this.$store.state.organization.selectedOrganization
      },
      items() {
        if (this.$route.params.organizationId) {
          return [{
              text: "Organization List",
              href: "/organization",
            },
            {
              text: "Organization Detail",
              href: "/organization",
            },
          ]
        }
        return [
          {
            text: "Organization Detail",
            href: "/organization",
          },
        ]
      },
      
      timezone() {
        return timezoneOptions.find(item => item.value == this.organizationDetail.timezone)?.label ?? this.organizationDetail.timezone
      },

      tableFields() {
        const tableFields = [{
            key: 'name',
            label: 'Name',
            class: 'text-break fit',
            sortable: true,
          }, {
            key: 'email',
            label: 'Email',
            class: 'text-break fit',
          }, {
            key: 'organization_role',
            label: 'Role',
            class: 'text-break fit',
            sortable: true,
          },
        ]
        if(this.userRole <= 1) {
          const action = [{
            key: 'action',
            label: 'Action',
            thStyle: {
              minWidth: '12rem',
            },
          }]
          return tableFields.concat(action) 
        } return tableFields
      }
    },
    watch: {
      routeQuery: {
        deep: true,
        immediate: true,
        handler() {
          if(this.organizationId) {
            this.getOrganizationDetail();
          }
        },
      },
      organizationId: {
        handler(organizationId) {
          if(!this.$route.params.organizationId && organizationId) {
            this.getOrganizationDetail();
          }
        },
      },
      filter: {
        deep: true,
        immediate: true,
        handler() {
          this.getOrganizationUsers();
        }
      }
    },
    methods: {
      highlightString,
      async getOrganizationDetail() {
        this.organizationDetail = {}
        this.organizationUsers = []
        try {
          this.isLoading = true;
          const { data } = await this.$api.getOrganizationDetail(this.organizationId);
          this.organizationDetail = data.data;
          this.getOrganizationUsers();
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async getOrganizationUsers() {
        try {
          this.isLoading = true;
          
          const { data } = await this.$api.getOrganizationUsers(this.organizationId, { ...this.filter });
          this.organizationUsers = data.data;
          this.organizationUsersMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async deleteData() {
        try {
          await this.$api.deleteOrganization(this.organizationDetail.id); 
          this.$router.push('/organization')

          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Deleted successfully',
              icon: 'ri-check-double-line',
            },
          };
          this.toast(toastContent);
        } catch (error) {
          console.log(error);

          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'danger',
              text: 'Process Failed',
            },
          };
          this.toast(toastContent);
        }
      },
      updateData() {
        this.filter.page = 1
        this.getOrganizationDetail()
      },
      handlePageChange(page) {
        this.filter.page = page
      },
      handlePerPageChange(perPage) {
        this.filter.page = 1
        this.filter.per_page = perPage
      },
    },
  };
</script>