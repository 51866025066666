<template>
  <b-modal
    id="organization-attach-user-modal"
    v-model="showModal"
    title="Attach User"
    centered
    hide-footer
    @shown="resetForm"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <b-form-group
        class="mb-3 required"
        label="User"
      >
        <Multiselect
          v-model="form.user_id"
          class="form-control email-compose-input"
          placeholder="Type to search the username"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :options="userOptions"
          :class="v$.form.user_id.$error ? 'invalid' : ''"
          @search-change="handleSearch"
        />
        <b-form-invalid-feedback
          v-if="v$.form.user_id.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.user_id.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
        <small
          v-if="!v$.form.user_id.$error"
          class="text-muted"
        >You can select only 1 user.</small>
      </b-form-group>
      <b-form-group
        class="mb-3 required"
        label="Role"
      >
        <Multiselect
          v-model="form.organization_role"
          class="form-control email-compose-input"
          placeholder="Select role"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :options="roleOptions"
          :class="v$.form.organization_role.$error ? 'invalid' : ''"
        />
        <b-form-invalid-feedback
          v-if="v$.form.organization_role.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.organization_role.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-between gap-2">
        <b-button
          variant="outline-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="d-flex">
          <b-button
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { roleOptions } from '@/utils/constant';
import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
      Multiselect,
  },
  props: {
    organizationDetail: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast, v$: useVuelidate() };
  },
  data() {
    return {
      showModal: false,
      form: {
        user_id: null,
        organization_role: null, 
      },
      allUsers: [],
      timerId: null,
    };
  },
  validations: {
    form: {
      user_id: {
        required: helpers.withMessage("Field select user is required.", required),
      },
      organization_role: {
        required: helpers.withMessage("Field select user role is required.", required),
      },
    },
  },
  computed: {
    roleOptions() {
      return roleOptions
    },
    userOptions() {
      if (this.allUsers) {
        return this.allUsers.map((item) => ({
          value: item.id, 
          label: item.name
        }))
      } return []
    },
    organizationId() {
      return this.organizationDetail.id;
    }
  },
  watch: {
    organizationId: {
      handler(organizationId) {
        if(organizationId) {
          this.getAllUsers();
        }
      },
    },
  },
  methods: {
    async getAllUsers(keyword) {
      try {
        this.isLoading = true;
        const { data } = await this.$api.getOrganizationUsers(this.organizationDetail.id, {
          show_all: 1,
          per_page: 100,
          keyword: keyword
        });
        this.allUsers = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
    },
    async submitForm() {
      try {
          await this.$api.attachOrganizationUsers(this.organizationId, this.form);
          this.$emit('update-data');
          // toast 
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'User Attached Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        this.closeModal();
      } catch (error) {
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    handleSearch(keyword) {
      console.log(keyword)
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getAllUsers(keyword);
      }, 300);
    },
  },
};
</script>

