<template>
  <b-modal
    id="detach-user-modal"
    v-model="showModal"
    title="Detach User"
    centered
    hide-footer
    size="sm"
  >
    <div class="mb-2">
      <b>
        {{ item.name }}
      </b>
    </div>
    <div>
      Are you sure to detach this user?
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="col-6 pe-2">
        <b-button
          variant="outline-primary"
          class="w-100"
          @click="deleteData"
        >
          Yes
        </b-button>
      </div>
      <div class="col-6 ps-2">
        <div class="d-flex">
          <b-button
            variant="primary"
            class="w-100"
            data-bs-dismiss="modal"
          >
            No
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    organizationDetail: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    async deleteData() {
      try {
        await this.$api.detachOrganizationUser(this.organizationDetail.id, this.item.id);
        this.$emit('update-data');
        // toast 
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'User Detached Successfully',
            icon: 'ri-check-double-line'
          }
        }
        this.toast(toastContent);
        this.showModal = false;
      } catch (error) {
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process failed',
          }
        }
        this.toast(toastContent);
      }
    },
  },
};
</script>
